<template>
  <ClubForm v-bind="props" />
</template>

<script>
import ClubForm from "@/components/forms/ClubForm.vue";
export default {
  components: { ClubForm },
  data() {
    return {
      props: {
        title: "Kulüp Oluştur",
        submit: (form) => {
          this.$store
            .dispatch("saveClub", form)
            .then(() => this.$router.push("/clubs"));
        },
        form: { region: "" },
      },
    };
  },
};
</script>

<style scoped>
</style>